import { connect, Field, FieldIntentCtx, RenderFieldExtensionCtx } from 'datocms-plugin-sdk';
import { render } from './utils/render';
import ConfigScreen from './entrypoints/ConfigScreen';
import 'datocms-react-ui/styles.css';
import ImageWithPinEditor from "./entrypoints/ImageWithPinEditor";
import FilterEditor from "./entrypoints/FilterEditor";

connect({
    renderConfigScreen(ctx) {
        return render(<ConfigScreen ctx={ctx} />);
    },
    renderFieldExtension(fieldExtensionId: string, ctx: RenderFieldExtensionCtx) {
        if (fieldExtensionId === 'image_with_pin') {
            return render(<ImageWithPinEditor ctx={ctx} />);
        }

        if (fieldExtensionId === 'povagFilterEditor') {
            return render(<FilterEditor ctx={ctx} />);
        }
    },
    overrideFieldExtensions(field: Field, ctx: FieldIntentCtx) {
        if (field.attributes.api_key === 'pins') {
            return {
                addons: [
                    {
                        id: 'image_with_pin',
                    }
                ]
            }
        } else if (field.attributes.api_key === 'filters') {
            return {
                editor: {
                    id: 'povagFilterEditor'
                }
            }
        }
    }
});
