import { RenderFieldExtensionCtx } from "datocms-plugin-sdk";
import { Button, Canvas, FieldGroup, Form, Section, SwitchField, TextField } from "datocms-react-ui";
import AsyncSelect from "react-select/async";
import axios from "../axios";
import Select, { SingleValue } from "react-select";
import { useEffect, useState } from "react";
import get from "lodash/get";

type Props = {
    ctx: RenderFieldExtensionCtx;
};

type AttributeOption = {
    value: null|string;
    label: string;
    metaOptions?: any;
    meta?: {
        relationType: string;
    };
};

async function getAttributes(query: null|string = null, filters: object = {}): Promise<AttributeOption[]> {
    // Set params based on using query or filters
    const params = query === null ? {
        usePagination: 0,
        filters      : JSON.stringify(filters),
        with         : [
            "tenantAttribute",
        ],
    } : {
        usePagination: 0,
        search       : query,
        with         : [
            "tenantAttribute",
        ],
    }
    const response = await axios.get("/datocms/attributes", {
        params,
    });

    return response.data?.data?.map((attribute: { id: number, identifier: string, tenantAttribute: any, meta: any }) => {
        return {
            value      : attribute.identifier,
            label      : attribute.tenantAttribute.name,
            metaOptions: attribute.tenantAttribute?.meta?.options ?? null,
            meta       : attribute.meta ?? null,
        };
    }) ?? [];
}

async function getRelations(type: string, query: string): Promise<AttributeOption[]> {
    const response = await axios.get(`/main/${type}`, {
        params: {
            search       : query,
            usePagination: 1,
            with         : type === 'categories' ? ["tenantCategory"] : [],
        },
    });

    return response.data?.data?.map((relation: any) => {
        if (type === 'products') {
            return {
                value: relation.id,
                label: relation.title.text ?? relation.title.default.text,
            };
        }

        return {
            value: relation.id,
            label: relation.name,
        };
    });
}

async function getRelation(relationType: string, value: string) {
    const response = await axios.get(`/main/${relationType}/${value}`, {
        params: {
            with: relationType === 'categories' ? ["tenantCategory"] : [],
        },

    });

    return response.data.data;
}

export default function FilterEditor({ ctx }: Props) {
    // The base field path is the path to the field
    let baseFieldPath = ctx.fieldPath.replace(/\.\w+$/, '');
    // @ts-ignore
    const fieldValue = get(ctx.itemValue, baseFieldPath);
    let filters = JSON.parse(fieldValue?.filters ?? "{}");

    // States per variable
    // filterTitles is used for the human-readable names of the filters
    const [filterTitles, setFilterTitles] = useState({} as { [key: string]: AttributeOption });
    const [isLoading, setIsLoading] = useState(true);

    const [selectedAttribute, setSelectedAttribute] = useState(null as null|AttributeOption);
    const [attributeValue, setAttributeValue] = useState(null as null|AttributeOption);
    const [filterVisible, setFilterVisible] = useState(true);
    const [error, setError] = useState(null as null|string);

    // Load the attributes when initializing
    useEffect(() => {
        // Once isLoading is false, we don't need to load the attributes anymore (this is a one-time thing)
        if (!isLoading) {
            return;
        }

        // Get the attributes that are currently selected as filters and store them in the filterTitles state
        getAttributes(null, { identifier: Object.keys(filters) }).then(async attributes => {
            const newFilterTitles = { ...filterTitles };
            const promises = [];

            for (const attribute of attributes) {
                const attributeValue = attribute.value;
                if (attributeValue == null) {
                    continue;
                }

                newFilterTitles[attributeValue] = attribute;

                const relationType = attribute.meta?.relationType;

                if (relationType != null) {
                    const identifier = filters[attributeValue].value;

                    promises.push(
                        getRelation(relationType, identifier)
                            .then((relation) => {
                                const value = relationType === 'categories'
                                    ? relation.name
                                    : relation.title.text ?? relation.title.default.text;

                                newFilterTitles[attributeValue].metaOptions = {
                                    [identifier]: {
                                        value,
                                    },
                                }
                            })
                    );
                }

            }

            await Promise.all(promises);
            setFilterTitles(newFilterTitles);
            setIsLoading(false);
        });
    }, [filterTitles, filters, isLoading]);

    const loadOptions = (
        inputValue: string,
        callback: (options: AttributeOption[]) => void,
    ) => {
        getAttributes(inputValue)
            .then((attributes) => {
                callback(attributes);
            });
    };
    const loadRelationOptions = (
        inputValue: string,
        callback: (options: AttributeOption[]) => void,
    ) => {
        getRelations(selectedAttribute?.meta?.relationType ?? 'categories', inputValue)
            .then(callback);
    };

    // Store the selected attribute in the state and clear the attribute value
    const attributeChanged = function (attribute: SingleValue<AttributeOption>) {
        setError(null);
        setSelectedAttribute(attribute);
        setAttributeValue(null);
        setFilterVisible(true);
    }

    const addFilter = function () {
        setError(null);
        // Add the new filter to the current value of the field
        const newFilters = JSON.parse(fieldValue?.filters ?? "{}");

        if (selectedAttribute?.value == null) {
            return;
        }

        if (attributeValue?.value == null || attributeValue?.value === '') {
            setError('Vul een waarde in');
            return;
        }

        const relationType = selectedAttribute.meta?.relationType ?? null;
        newFilters[selectedAttribute.value] = {
            value       : attributeValue?.value,
            operator    : '=',
            visible     : filterVisible,
            relationType: relationType,
        };

        filters = newFilters;
        // Store the new filters in the field for Dato
        ctx.setFieldValue(`${baseFieldPath}.filters`, JSON.stringify(newFilters));

        if (relationType === null) {
            // Add the filter to the filterTitles state to display in the table
            setFilterTitles({ ...filterTitles, [selectedAttribute.value]: selectedAttribute });
        } else {
            const label = attributeValue?.label ?? '';
            setFilterTitles({
                ...filterTitles, [selectedAttribute.value]: {
                    ...selectedAttribute,
                    metaOptions: {
                        [attributeValue?.value ?? '']: {
                            value: label,
                        },
                    },
                }
            });
        }

        setFilterVisible(true);
        setAttributeValue(null);
        setSelectedAttribute(null);
    }

    // Delete a filter from the field and the filterTitles state
    const deleteFilter = function (id: string) {
        const newFilters = JSON.parse(fieldValue?.filters ?? "{}");

        delete newFilters[id];

        filters = newFilters;
        ctx.setFieldValue(`${baseFieldPath}.filters`, JSON.stringify(newFilters));
        setFilterTitles(Object.fromEntries(Object.entries(filterTitles).filter(([key, _]) => key !== id)));
    }

    // Rendering logic

    // If the filters are still loading, show a loading message
    if (isLoading) {
        return (
            <Canvas ctx={ctx}>
                <div>
                    <p>Aan het laden...</p>
                </div>
            </Canvas>
        );
    }

    // Conditional render based on the selected attribute
    let conditionalRender = null;

    if (selectedAttribute !== null) {
        const conditionalFooter = <div style={{ marginTop: '16px' }}>
            <SwitchField
                id="filterVisible"
                name="filterVisible"
                label="Zichtbaar"
                value={filterVisible}
                onChange={(value) => setFilterVisible(value)}
            />
            <Button style={{ marginTop: '16px' }} onClick={addFilter}>Voeg filter toe</Button>
        </div>

        // If the selected attribute has metaOptions, render a select with the options
        if (selectedAttribute.metaOptions != null) {
            const options = Object.entries(selectedAttribute.metaOptions).map(([key, value]: any) => {
                return {
                    value: key,
                    label: value.value,
                }
            });

            conditionalRender = <div>
                <label style={{color: error == null ? 'inherit' : 'var(--alert-color)'}}>Kies de filter waarde</label>
                <Select onChange={(value) => setAttributeValue(value)}
                        options={options} />
                <p style={{color: 'var(--alert-color)'}}>{error}</p>
                {conditionalFooter}
            </div>
        } else if (selectedAttribute.meta?.relationType != null) {
            conditionalRender = <div>
                <label style={{ color: error == null ? 'inherit' : 'var(--alert-color)' }}>Kies de filter waarde</label>
                <AsyncSelect cacheOptions
                             loadOptions={loadRelationOptions}
                             defaultOptions
                             onChange={setAttributeValue} />
                <p style={{ color: 'var(--alert-color)' }}>{error}</p>
                {conditionalFooter}
            </div>
        } else {
            // For non-select or non-relation attributes, we render a free text field as input
            conditionalRender = <div>
                <FieldGroup>
                    <TextField
                        id="filterValue"
                        name="filterValue"
                        label="Filter waarde"
                        error={error}
                        placeholder="Vul een waarde in"
                        value={attributeValue?.value ?? ''}
                        onChange={(e: string) => {
                            setAttributeValue({ value: e, label: e })
                        }}
                    />
                </FieldGroup>
                {conditionalFooter}
            </div>
        }
    }

    const conditionalTable = Object.keys(filters).length === 0
        ? <div style={{fontSize: '12px'}}>Nog geen filters toegevoegd</div>
        : <table className={"filter-list"}>
            <thead>
                <tr>
                    <th>
                        Attribuut
                    </th>
                    <th>
                        Operator
                    </th>
                    <th>
                        Waarde
                    </th>
                    <th>
                        Zichtbaar
                    </th>
                    <th>
                        Acties
                    </th>
                </tr>
            </thead>
            <tbody>
                {Object.entries(filters).map(([id, filter]: any[]) => (
                    <tr key={`filter-${id}`}>
                        <td>
                            {filterTitles[id]?.label ?? 'null'}
                        </td>
                        <td>
                            {filter.operator}
                        </td>
                        <td>
                            {filterTitles[id]?.metaOptions?.[filter.value]?.value ?? filter.value}
                        </td>
                        <td style={{ opacity: 0.7, pointerEvents: 'none' }}>
                            <SwitchField
                                id={`switch-${id}`}
                                name={`switch-${id}`}
                                label={null}
                                value={filter.visible}
                                onChange={() => {
                                }}
                            />
                        </td>
                        <td onClick={() => deleteFilter(id)}>
                            <svg style={{ cursor: 'pointer' }} fill="var(--alert-color)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="1em" height="1em">
                                <path d="M177.1 48h93.7c2.7 0 5.2 1.3 6.7 3.6l19 28.4h-145l19-28.4c1.5-2.2 4-3.6 6.7-3.6zM354.2 80L317.5 24.9C307.1 9.4 289.6 0 270.9 0H177.1c-18.7 0-36.2 9.4-46.6 24.9L93.8 80H80.1 32 24C10.7 80 0 90.7 0 104s10.7 24 24 24H35.6L59.6 452.7c2.5 33.4 30.3 59.3 63.8 59.3H324.6c33.5 0 61.3-25.9 63.8-59.3L412.4 128H424c13.3 0 24-10.7 24-24s-10.7-24-24-24h-8H367.9 354.2zm10.1 48L340.5 449.2c-.6 8.4-7.6 14.8-16 14.8H123.4c-8.4 0-15.3-6.5-16-14.8L83.7 128H364.3z"></path>
                            </svg>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>

    // Render the table with the current filters and the filter to add
    return (
        <Canvas ctx={ctx}>
            <Form>
                {conditionalTable}

                <Section title={"Filter toevoegen"}>
                    <div style={{ marginBottom: "16px" }}>
                        <label>Kies je attribuut</label>
                        <AsyncSelect cacheOptions
                                     loadOptions={loadOptions}
                                     defaultOptions
                                     value={selectedAttribute}
                                     onChange={attributeChanged} />
                    </div>
                    {conditionalRender}
                </Section>
            </Form>
        </Canvas>
    )
}
