import axios from 'axios';
import { clientCredentials } from 'axios-oauth-client';

const getClientCredentials = clientCredentials(
    axios.create(),
    `https://${process.env.REACT_APP_API_URL}/oauth/token`,
    process.env.REACT_APP_API_CLIENT_ID,
    process.env.REACT_APP_API_CLIENT_SECRET,
);

const credentials = await getClientCredentials('');

const axiosInstance = axios.create({
    baseURL: `https://${process.env.REACT_APP_API_URL}`,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${credentials.access_token}`,
        "X-Tenant": "povag_nl_NL",
    },
});

export default axiosInstance;
