import { RenderFieldExtensionCtx } from 'datocms-plugin-sdk';
import { Canvas } from "datocms-react-ui";
import get from 'lodash/get';
import { useEffect, useState } from "react";
import PinComponent from './PinComponent';

type Pin = {
    x_axis: number,
    y_axis: number,
}

type Props = {
    ctx: RenderFieldExtensionCtx;
};

export default function ImageWithPinEditor({ ctx }: Props) {
    let baseFieldPath = ctx.fieldPath.replace(/\.\w+$/, '');
    // @ts-ignore
    const imageWithPinBlock = get(ctx.itemValue, baseFieldPath);
    const [state, setState] = useState(['']);

    useEffect(() => {
        fetch("https://graphql.datocms.com/", {
            "headers": {
                "accept"         : "application/json, multipart/mixed, application/json",
                "accept-language": "en-US,en;q=0.9,nl;q=0.8",
                "authorization"  : "Bearer " + process.env.REACT_APP_DATO_API_TOKEN,
                "content-type"   : "application/json",
                "x-environment"  : ctx.environment,
            },
            "body"   : "{\"query\":\"{\\n  upload(filter: {id: {eq: \\\"" + imageWithPinBlock.image?.upload_id + "\\\"}}) {\\n    url\\n  }\\n}\"}",
            "method" : "POST"
        }).then(async response => {
            const { data } = await response.json();

            setState([data.upload.url]);
        });
    });

    const onPinChanged = async (newPinData: Pin, pinIdx: number) => {
        await ctx.setFieldValue(`${baseFieldPath}.pins.${pinIdx}.x_axis`, newPinData.x_axis);
        await ctx.setFieldValue(`${baseFieldPath}.pins.${pinIdx}.y_axis`, newPinData.y_axis);
    };

    return (
        <Canvas ctx={ctx}>
            {state.map((d, idx) => <PinComponent key={`image-${d}-${idx}`}
                                                 image={d}
                                                 onPinChanged={onPinChanged}
                                                 pins={imageWithPinBlock.pins} />)}
        </Canvas>
    );
}
