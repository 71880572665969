import styles from './pincomponent.module.css';

type Pin = {
    x_axis: number,
    y_axis: number,
}

type Props = {
    pins: Pin[],
    image: string,
    onPinChanged: (newPinData: Pin, pinIdx: number) => void,
};

function dragEnd(e: any, pin: Pin): Pin {
    e.preventDefault();
    const newX = e.clientX;
    const newY = e.clientY;
    const width = e.view.innerWidth;
    const height = e.view.innerHeight;
    const x = Math.round(newX / width * 100);
    const y = Math.round(newY / height * 100);

    return {
        x_axis: x,
        y_axis: y,
    };
}

function dragOver(e: any) {
    e.preventDefault();
}

export default function PinComponent({ image, pins, onPinChanged }: Props) {
    return (
        <div onDragOver={dragOver}>
            <img style={{ width: "100%", height: "auto", objectFit: "contain" }} src={image} alt={"product"} />
            {pins?.map((pin, pinIdx) => {
                return (
                    <div key={`pin-${pinIdx}`} onDragEnd={(e) => onPinChanged(dragEnd(e, pin), pinIdx)} draggable={true} className={styles.imagepin} style={{ left: pin.x_axis + '%', top: pin.y_axis + '%' }}>
                        <div className={styles.pin}>
                            <div className={styles.circle} />
                        </div>
                        {/*For now this is disabled as we can only retrieve the ID of the selected product...*/}
                        {/*<div className={styles.pinPopover}>*/}
                        {/*    <div className={styles.top}>*/}
                        {/*        <p className={styles.header}>PRODUCT</p>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                );
            })}
        </div>
    )
};
